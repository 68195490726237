import React, { useState, useEffect, useRef } from "react";
import { Dimensions, ScrollView, View, Pressable, StyleSheet, Platform } from "react-native";
import {
    Button,
    Card,
    Checkbox,
    DataTable,
    Dialog,
    IconButton,
    Portal,
    RadioButton,
    Text,
    Title,
    withTheme,
} from "react-native-paper";
import { Link } from "../../routers/routing";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { Picker } from "@react-native-picker/picker";
import { createYupSchema, desktopBreakpoint, getContrastColor } from "../../shared/helpers";
import { styles } from "../../shared/styles";
import CustomerCheckoutAddAddress from "./CustomerCheckoutAddAddress";
import { customerDataStyles } from "./CustomerDataStyles";
import CustomInputField from "../../helpers/inputField/CustomInputField";
import MissingAddressPopup from "./MissingAddressPopup";
import AddAddressPopup from "./AddAddressPopup";
import { Formik, Form } from "formik";
import { getCustomerAddresses } from "../../store/actions/accountActions";
import CustomDropdown from "../../helpers/inputField/CustomDropdown";

const TitleRadioButtons = ({ field, props, error, customer }) => {
    const [checked, setChecked] = useState(() => props.values[field.id] || "Herr");

    useEffect(() => {
        if (!props.values[field.id]) {
            props.setFieldValue(field.id, "Herr");
        }
    }, []);

    const handleRadioChange = (value) => {
        props.setFieldValue(field.id, value);
        setChecked(value);
    };

    if (customer) return null;

    return (
        <View key={field.id} id={field.id} style={customerDataStyles.fieldRow}>
            <Text>Anrede</Text>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
                {["Herr", "Frau", "Divers"].map((option) => (
                    <Pressable key={option} onPress={() => handleRadioChange(option)}>
                        <View style={{ flexDirection: "row", alignItems: "center", marginRight: 15 }}>
                            <RadioButton.IOS
                                value={option}
                                status={checked === option ? "checked" : "unchecked"}
                                onPress={() => handleRadioChange(option)}
                            />
                            <Text>{option}</Text>
                        </View>
                    </Pressable>
                ))}
            </View>
            {error && (
                <View>
                    <Text style={styles.pickerError}>{props.errors[field.id] ? props.errors[field.id] : ""}</Text>
                </View>
            )}
        </View>
    );
};

const CustomerData = (props) => {
    const [useDifferentBillingAddress, setUseDifferentBillingAddress] = useState(false);
    const [customerData, setCustomerData] = useState(null);
    const [windowWidth, setWindowWidth] = useState(Dimensions.get("window").width);
    const [windowHeight, setWindowHeight] = useState(Dimensions.get("window").height);
    const [addresses, setAddresses] = useState([]);
    const [paymentMethod, setPaymentMethod] = useState("");
    const [step, setStep] = useState(0);
    const [isFormValid, setIsFormValid] = useState(false);
    const [showMissingAddressPopup, setShowMissingAddressPopup] = useState(false);
    const [showAddAddressPopup, setShowAddAddressPopup] = useState(false);
    const [additionalAddressToEdit, setAdditionalAddressToEdit] = useState(null);
    const [edit, setEdit] = useState(false);
    const [datenschutzIsChecked, setDatenschutzIsChecked] = useState(false);

    const customerDataForm = useRef(null);
    const dispatch = useDispatch();
    const account = useSelector((state) => state.account);
    const settings = useSelector((state) => state.settings);
    const { theme } = props;

    useEffect(() => {
        const updateDimensions = () => {
            setWindowWidth(Dimensions.get("window").width);
            setWindowHeight(Dimensions.get("window").height);
        };

        Dimensions.addEventListener("change", updateDimensions);

        return () => {
            Dimensions.removeEventListener("change", updateDimensions);
            document.body.style.overflow = "scroll";
        };
    }, [account.customer]);

    useEffect(() => {
        if (props.open && account.customer) {
            fetchCustomerAddresses(props.keycloak.token);
        }
    }, [props.open, account.customer]);

    useEffect(() => {
        if (!props.open) {
            setDatenschutzIsChecked(false);
            setStep(0);
        }
    }, [props.open]);

    const fetchCustomerAddresses = async (token) => {
        try {
            const response = await dispatch(getCustomerAddresses(token));
            if (response.ok) {
                const addresses = await response.json();
                setAddresses(addresses);

                if (
                    addresses &&
                    addresses.find((address) => address.isDefaultBillingAddress)?.addressId !==
                        addresses.find((address) => address.isDefaultDeliveryAddress)?.addressId
                ) {
                    customerDataForm.current.setFieldValue("useDifferentBillingAddress", true);
                } else {
                    customerDataForm.current.setFieldValue("useDifferentBillingAddress", false);
                }
            } else {
                console.error("Failed to fetch customer addresses:", response.statusText);
            }
        } catch (error) {
            console.error("Error fetching customer addresses:", error);
        }
    };

    const refreshAddresses = () => {
        fetchCustomerAddresses(props.keycloak.token);
    };

    const buildInitialValues = async () => {
        const initialValues = {};
        const { customer } = account;

        settings.customerData.fields.forEach((field) => {
            let initialValue = field.value || "";
            if (field.id === "printBill" || field.id === "printRecipe" || field.id === "acceptTerms") {
                initialValue = false;
            } else if (field.id === "billingAddress") {
                if (customer && addresses) {
                    let address = addresses.filter((address) => address.isDefaultBillingAddress)[0];
                    initialValue = JSON.stringify(address);
                }
            } else if (field.id === "deliveryAddress") {
                if (customer && addresses) {
                    let address = addresses.filter((address) => address.isDefaultDeliveryAddress)[0];
                    initialValue = JSON.stringify(address);
                }
            } else if (field.id === "useDifferentBillingAddress") {
                if (
                    customer &&
                    addresses &&
                    addresses.find((address) => address.isDefaultBillingAddress)?.addressId !==
                        addresses.find((address) => address.isDefaultDeliveryAddress)?.addressId
                ) {
                    initialValue = true;
                }
            } else if (field.id === "country") {
                const defaultCountry = settings.store.countries.find((country) => country.isDefault);
                initialValue = defaultCountry ? defaultCountry.countryId : initialValue;
            } else if (field.id === "billingCountry") {
                const defaultCountry = settings.store.countries.find((country) => country.isDefault);
                initialValue = defaultCountry ? defaultCountry.countryId : initialValue;
            } else {
                if (account.customer) {
                    initialValue = account.customer[field.id] ? account.customer[field.id] : initialValue;
                }
            }

            initialValues[field.id] = field.value || initialValue;
        });

        initialValues.paymentMethod = settings.store.availablePaymentTypes[0]?.identifier;

        return initialValues;
    };

    const toggleAddAddressPopup = () => {
        setShowAddAddressPopup(!showAddAddressPopup);
        setAdditionalAddressToEdit(edit ? null : additionalAddressToEdit);
        setEdit(false);
    };

    const openAddAddressPopup = () => {
        setShowAddAddressPopup(true);
    };

    const toggleMissingAddressPopup = () => {
        setShowMissingAddressPopup(!showMissingAddressPopup);
    };

    const nextStep = () => {
        setStep((prevStep) => prevStep + 1);
    };

    const prevStep = () => {
        setStep((prevStep) => prevStep - 1);
    };

    const formElements = ({ keycloak, ...props }) => {
        return settings.customerData.fields.map((field, index) => {
            const error = props.errors.hasOwnProperty(field.id) && props.errors[field.id];

            const { customer } = account;

            const isBillingAddressAttribute = [
                "billingTitle",
                "billingFirstName",
                "billingLastName",
                "billingStreet",
                "billingHousenumber",
                "billingZipCode",
                "billingCity",
                "billingCountry",
            ].includes(field.id);

            const shouldDisplayField =
                (customer && field.showWhenLoggedIn) ||
                (!customer && field.showWhenNotLoggedIn && !isBillingAddressAttribute) ||
                (!customer && isBillingAddressAttribute && props.values["useDifferentBillingAddress"]) ||
                field.type === "useDifferentBillingAddress";

            if (!shouldDisplayField) {
                return null;
            }

            if (field.type === "text") {
                const fieldId = isBillingAddressAttribute ? `billing-${field.id}` : field.id;

                return (
                    <View
                        key={field.id}
                        id={field.id}
                        style={
                            windowWidth <= desktopBreakpoint
                                ? customerDataStyles.fieldFormFullRow
                                : customerDataStyles.fieldFormSplitRow
                        }
                    >
                        <CustomInputField
                            key={index}
                            id={fieldId}
                            label={field.placeholder}
                            disabled={field.disabled}
                            onChange={props.handleChange(field.id)}
                            value={props.values[field.id] || ""}
                            error={!!props.errors[field.id]}
                            helperText={props.errors[field.id] || ""}
                        />
                    </View>
                );
            }

            if (field.id === "country") {
                const availableCountries = settings.store.countries || [];
                const sortedCountries = [...availableCountries].sort((a, b) => a.name.localeCompare(b.name));
                const isSingleCountry = sortedCountries.length === 1;

                const defaultCountry = sortedCountries.find((country) => country.isDefault);

                return (
                    <View
                        key={field.id}
                        id={field.id}
                        style={
                            windowWidth <= desktopBreakpoint
                                ? customerDataStyles.fieldFormFullRow
                                : customerDataStyles.fieldFormSplitRow
                        }
                    >
                        <CustomDropdown
                            label="Land"
                            error={props.errors[field.id] ? true : false}
                            helperText={props.errors[field.id] ? props.errors[field.id] : ""}
                            onChange={(value) => props.setFieldValue(field.id, value)}
                            items={sortedCountries.map((country) => ({
                                label: country.name,
                                value: country.countryId,
                            }))}
                            value={props.values[field.id] || (defaultCountry ? defaultCountry.countryId : "")}
                            placeholder="Wählen Sie ein Land"
                            disabled={isSingleCountry}
                        />
                    </View>
                );
            }

            if (field.id === "billingCountry") {
                const availableCountries = settings.store.countries || [];
                const sortedCountries = [...availableCountries].sort((a, b) => a.name.localeCompare(b.name));
                const isSingleCountry = sortedCountries.length === 1;

                return (
                    <View
                        key={field.id}
                        id={field.id}
                        style={
                            windowWidth <= desktopBreakpoint
                                ? customerDataStyles.fieldFormFullRow
                                : customerDataStyles.fieldFormSplitRow
                        }
                    >
                        <CustomDropdown
                            label="Land"
                            error={props.errors[field.id] ? true : false}
                            helperText={props.errors[field.id] ? props.errors[field.id] : ""}
                            onChange={(value) => props.setFieldValue(field.id, value)}
                            items={sortedCountries.map((country) => ({
                                label: country.name,
                                value: country.countryId,
                            }))}
                            value={props.values[field.id]}
                            placeholder="Wählen Sie ein Land"
                            disabled={isSingleCountry}
                        />
                    </View>
                );
            }

            if (field.type === "password") {
                return (
                    <View key={field.id} id={field.id} style={customerDataStyles.fieldRow}>
                        <CustomInputField
                            key={index}
                            type="password"
                            label={field.placeholder}
                            disabled={field.disabled}
                            onChange={props.handleChange(field.id)}
                            value={props.values[field.id] || ""}
                            error={!!props.errors[field.id]}
                            helperText={props.errors[field.id] || ""}
                        />
                    </View>
                );
            }

            if (field.type === "textarea") {
                return (
                    <View key={field.id} id={field.id} style={customerDataStyles.fieldRow}>
                        <CustomInputField
                            key={index}
                            multiline
                            minRows={5}
                            label={field.placeholder}
                            disabled={field.disabled}
                            onChange={props.handleChange(field.id)}
                            value={props.values[field.id] || ""}
                            error={!!props.errors[field.id]}
                            helperText={props.errors[field.id] || ""}
                        />
                    </View>
                );
            }

            if (field.type === "printBillCheckbox") {
                return (
                    <View key={field.id} id={field.id} style={customerDataStyles.fieldRow}>
                        <Checkbox.Item
                            label="Rechnung drucken"
                            status={props.values[field.id] ? "checked" : "unchecked"}
                            onPress={() => props.setFieldValue(field.id, !props.values[field.id])}
                            uncheckedColor={theme.colors.primary}
                            color={getContrastColor(theme.colors.primary)}
                            style={[
                                customerDataStyles.checkbox,
                                {
                                    backgroundColor: props.values[field.id]
                                        ? theme.colors.primary
                                        : theme.colors.surface,
                                    borderColor: theme.colors.primary,
                                },
                            ]}
                            labelStyle={{
                                color: props.values[field.id]
                                    ? getContrastColor(theme.colors.primary)
                                    : getContrastColor(theme.colors.surface),
                            }}
                            position="leading"
                            rippleColor="transparent"
                            mode="android"
                        />
                    </View>
                );
            }

            if (field.id === "acceptTerms") {
                return (
                    <View key={field.id} id={field.id} style={customerDataStyles.fieldRow}>
                        <Checkbox.Item
                            label="Ich akzeptiere die AGB und Datenschutzbestimmungen"
                            status={props.values[field.id] ? "checked" : "unchecked"}
                            onPress={() => {
                                props.setFieldValue(field.id, !props.values[field.id]);
                                setDatenschutzIsChecked(!datenschutzIsChecked);
                            }}
                            uncheckedColor={theme.colors.primary}
                            color={getContrastColor(theme.colors.primary)}
                            style={[
                                customerDataStyles.checkbox,
                                {
                                    backgroundColor: props.values[field.id]
                                        ? theme.colors.primary
                                        : theme.colors.surface,
                                    borderColor: theme.colors.primary,
                                },
                            ]}
                            labelStyle={{
                                color: props.values[field.id]
                                    ? getContrastColor(theme.colors.primary)
                                    : getContrastColor(theme.colors.surface),
                            }}
                            position="leading"
                            rippleColor="transparent"
                            mode="android"
                        />
                        {props.errors[field.id] && (
                            <Text style={[customerDataStyles.helperText, { color: theme.colors.error }]}>
                                Bitte akzeptieren Sie die AGB und Datenschutzbestimmungen
                            </Text>
                        )}
                        <Text style={customerDataStyles.helperText}>
                            Unsere Datenschutzbestimmungen / AGB können Sie
                            {Platform.OS === "web" ? (
                                <a
                                    style={{
                                        ...StyleSheet.flatten(customerDataStyles.customLink),
                                        color: theme.colors.text,
                                    }}
                                    href="/datenschutz"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    hier
                                </a>
                            ) : (
                                <Link
                                    style={{
                                        ...StyleSheet.flatten(customerDataStyles.customLink),
                                        color: theme.colors.text,
                                    }}
                                    to="/datenschutz"
                                >
                                    hier
                                </Link>
                            )}
                            finden
                        </Text>
                    </View>
                );
            }

            if (field.id === "title" || field.id === "billingTitle") {
                return (
                    <TitleRadioButtons key={field.id} field={field} props={props} error={error} customer={customer} />
                );
            }

            if (field.type === "useDifferentBillingAddress") {
                return (
                    <View key={field.id} id={field.id} style={[customerDataStyles.fieldRow, { marginTop: 15 }]}>
                        <Checkbox.Item
                            label="Abweichende Rechnungsadresse"
                            status={props.values[field.id] ? "checked" : "unchecked"}
                            onPress={() => props.setFieldValue(field.id, !props.values[field.id])}
                            uncheckedColor={theme.colors.primary}
                            color={getContrastColor(theme.colors.primary)}
                            style={[
                                customerDataStyles.checkbox,
                                {
                                    backgroundColor: props.values[field.id]
                                        ? theme.colors.primary
                                        : theme.colors.surface,
                                    borderColor: theme.colors.primary,
                                },
                            ]}
                            labelStyle={{
                                color: props.values[field.id]
                                    ? getContrastColor(theme.colors.primary)
                                    : getContrastColor(theme.colors.surface),
                            }}
                            position="leading"
                            rippleColor="transparent"
                            mode="android"
                        />
                    </View>
                );
            }

            if (field.type === "selectAddress") {
                const { customer } = account;
                const tempAddresses = addresses.length > 0 ? addresses : [];
                const [selectedAddress, setSelectedAddress] = useState(0);

                const sortedDeliveryAddresses = [...tempAddresses].sort((a, b) => {
                    if (a.isDefaultDeliveryAddress) return -1;
                    if (b.isDefaultDeliveryAddress) return 1;
                    return a.addressId - b.addressId;
                });

                if (sortedDeliveryAddresses && sortedDeliveryAddresses.length > 0 && !props.values["deliveryAddress"]) {
                    props.setFieldValue("deliveryAddress", JSON.stringify(sortedDeliveryAddresses[0]));
                }

                const sortedBillingAddresses = [...tempAddresses].sort((a, b) => {
                    if (a.isDefaultBillingAddress) return -1;
                    if (b.isDefaultBillingAddress) return 1;
                    return a.addressId - b.addressId;
                });

                if (sortedBillingAddresses && sortedBillingAddresses.length > 0 && !props.values["billingAddress"]) {
                    props.setFieldValue("billingAddress", JSON.stringify(sortedBillingAddresses[0]));
                }
                return (
                    customer &&
                    (field.id === "deliveryAddress" || props.values["useDifferentBillingAddress"]) && (
                        <View key={field.id} id={field.id} style={{ width: "100%" }}>
                            <Text style={{ width: "100%", marginBottom: 15, marginLeft: 10, fontWeight: "bold" }}>
                                {field.id === "deliveryAddress" ? "Lieferadresse" : "Rechnungsadresse"}
                            </Text>
                            <View style={{ display: "flex", flexWrap: "wrap", flexDirection: "row", width: "100%" }}>
                                {field.id === "deliveryAddress" &&
                                    sortedDeliveryAddresses &&
                                    sortedDeliveryAddresses.map((address, index) => {
                                        return (
                                            <Card
                                                onPress={() => {
                                                    props.setFieldValue(field.id, JSON.stringify(address));
                                                    setSelectedAddress(index);
                                                }}
                                                style={{
                                                    backgroundColor:
                                                        selectedAddress === index
                                                            ? theme.colors.primary
                                                            : theme.colors.surface,
                                                    borderColor: theme.colors.primary,
                                                    borderWidth: "1px",
                                                    borderStyle: "solid",
                                                    width:
                                                        windowWidth <= 768 ? "calc(100% - 10px)" : "calc(33% - 10px)",
                                                    display: "flex",
                                                    margin: 5,
                                                    cursor: "pointer",
                                                }}
                                                key={index}
                                            >
                                                <Card.Title
                                                    titleStyle={{
                                                        color:
                                                            selectedAddress === index
                                                                ? getContrastColor(theme.colors.primary)
                                                                : theme.colors.onSurface,
                                                    }}
                                                    title={address.firstName + " " + address.lastName}
                                                />
                                                <Card.Content>
                                                    <Text
                                                        style={{
                                                            color:
                                                                selectedAddress === index
                                                                    ? getContrastColor(theme.colors.primary)
                                                                    : theme.colors.onSurface,
                                                        }}
                                                    >
                                                        {address.street +
                                                            " " +
                                                            address.housenumber +
                                                            "\n" +
                                                            address.zip +
                                                            " " +
                                                            address.city +
                                                            "\n" +
                                                            address.country +
                                                            "\n\n" +
                                                            (address.company ? address.company : "") +
                                                            "\n" +
                                                            (address.addressDetails ? address.addressDetails : "")}
                                                    </Text>
                                                </Card.Content>
                                            </Card>
                                        );
                                    })}
                                {field.id === "billingAddress" &&
                                    sortedBillingAddresses &&
                                    sortedBillingAddresses.map((address, index) => {
                                        return (
                                            <Card
                                                onPress={() => {
                                                    props.setFieldValue(field.id, JSON.stringify(address));
                                                    setSelectedAddress(index);
                                                }}
                                                style={{
                                                    backgroundColor:
                                                        selectedAddress === index
                                                            ? theme.colors.primary
                                                            : theme.colors.surface,
                                                    borderColor: theme.colors.primary,
                                                    borderWidth: "1px",
                                                    borderStyle: "solid",
                                                    width:
                                                        windowWidth <= 768 ? "calc(100% - 10px)" : "calc(33% - 10px)",
                                                    display: "flex",
                                                    margin: 5,
                                                    cursor: "pointer",
                                                }}
                                                key={index}
                                            >
                                                <Card.Title
                                                    titleStyle={{
                                                        color:
                                                            selectedAddress === index
                                                                ? getContrastColor(theme.colors.primary)
                                                                : theme.colors.onSurface,
                                                    }}
                                                    title={address.firstName + " " + address.lastName}
                                                />
                                                <Card.Content>
                                                    <Text
                                                        style={{
                                                            color:
                                                                selectedAddress === index
                                                                    ? getContrastColor(theme.colors.primary)
                                                                    : theme.colors.onSurface,
                                                        }}
                                                    >
                                                        {address.street +
                                                            " " +
                                                            address.housenumber +
                                                            "\n" +
                                                            address.zip +
                                                            " " +
                                                            address.city +
                                                            "\n" +
                                                            address.country +
                                                            "\n\n" +
                                                            (address.company ? address.company : "") +
                                                            "\n" +
                                                            (address.addressDetails ? address.addressDetails : "")}
                                                    </Text>
                                                </Card.Content>
                                            </Card>
                                        );
                                    })}
                                <CustomerCheckoutAddAddress update={refreshAddresses} keycloak={keycloak} />
                            </View>
                        </View>
                    )
                );
            }

            return "";
        });
    };

    const paymentForm = (props, windowWidth) => {
        return (
            <View
                style={
                    windowWidth <= desktopBreakpoint
                        ? customerDataStyles.containerFullRow
                        : customerDataStyles.containerSplitRow
                }
            >
                <View style={{ width: "100%" }}>
                    <View style={{ paddingBottom: 10 }}>
                        <Text style={customerDataStyles.headlineText}>Bitte wählen Sie eine Zahlungsart</Text>
                        <Picker
                            id="paymentMethod"
                            title="Bitte wählen Sie eine Zahlungsart"
                            selectedValue={props.values.paymentMethod}
                            onValueChange={(value) => {
                                props.setFieldValue("paymentMethod", value);
                            }}
                            onBlur={props.handleBlur}
                            style={styles.picker}
                        >
                            {settings.store.availablePaymentTypes.map(({ name, identifier }) => (
                                <Picker.Item key={name} label={name} value={identifier} />
                            ))}
                        </Picker>
                        {props.errors.paymentMethod && (
                            <Text style={[customerDataStyles.helperText, { color: theme.colors.error }]}>
                                {props.errors.paymentMethod}
                            </Text>
                        )}
                    </View>
                </View>
            </View>
        );
    };

    const initialValues = {
        ...buildInitialValues(),
        paymentMethod: settings.store.availablePaymentTypes[0]?.identifier,
        acceptTerms: false,
        country:
            buildInitialValues().country ||
            settings.store.countries.find((country) => country.isDefault)?.countryId ||
            "",
    };
    const yupSchema = settings.customerData.fields.reduce((schema, fieldConfig) => {
        return createYupSchema(schema, fieldConfig, account.customer);
    }, {});
    const validateSchema = yup.object().shape(yupSchema);

    return (
        <Portal>
            <Dialog
                visible={props.open}
                onDismiss={props.hideCustomerData}
                style={[
                    styles.addressDialogContainer,
                    {
                        backgroundColor: theme.colors.surface,
                        left: windowWidth <= desktopBreakpoint ? "calc(50% - 45vw)" : "calc(50% - 25vw)",
                        width: windowWidth <= desktopBreakpoint ? "90vw" : "50vw",
                    },
                ]}
                dismissable={false}
            >
                <IconButton
                    icon="close"
                    size={24}
                    onPress={props.hideCustomerData}
                    style={[styles.dialogCloseButton, { backgroundColor: theme.colors.primary }]}
                    iconColor={getContrastColor(theme.colors.primary)}
                    rippleColor="transparent"
                />

                {step === 0 && <Dialog.Title style={styles.dialogHeadline}>Schritt 1: Kundendaten</Dialog.Title>}
                {step === 1 && <Dialog.Title style={styles.dialogHeadline}>Schritt 2: Zahlungsinfo</Dialog.Title>}
                {step === 2 && <Dialog.Title style={styles.dialogHeadline}>Schritt 3: Zusammenfassung</Dialog.Title>}

                <Dialog.ScrollArea
                    style={{
                        borderColor: theme.colors.surface,
                    }}
                >
                    <ScrollView style={customerDataStyles.container}>
                        <DialogContent
                            theme={theme}
                            initialValues={initialValues}
                            validateSchema={validateSchema}
                            settings={settings}
                            customerDataForm={customerDataForm}
                            formElements={formElements}
                            paymentForm={paymentForm}
                            windowWidth={windowWidth}
                            windowHeight={windowHeight}
                            step={step}
                            setIsFormValid={setIsFormValid}
                            handleSubmit={props.handleSubmit}
                            keycloak={props.keycloak}
                        />
                    </ScrollView>
                    <DialogActions
                        windowWidth={windowWidth}
                        theme={theme}
                        hideCustomerData={props.hideCustomerData}
                        account={account}
                        customerDataForm={customerDataForm}
                        submitButtonName={props.submitButtonName}
                        step={step}
                        isFormValid={isFormValid}
                        nextStep={nextStep}
                        prevStep={prevStep}
                        paymentMethod={paymentMethod}
                        datenschutzIsChecked={datenschutzIsChecked}
                        addresses={addresses}
                        toggleMissingAddressPopup={toggleMissingAddressPopup}
                    />
                </Dialog.ScrollArea>
                {showMissingAddressPopup && (
                    <MissingAddressPopup
                        visible={showMissingAddressPopup}
                        toggleOverlay={toggleMissingAddressPopup}
                        onAddAddress={openAddAddressPopup}
                        windowHeight={windowHeight}
                    />
                )}
                {showAddAddressPopup && (
                    <AddAddressPopup
                        visible={showAddAddressPopup}
                        togglePopup={toggleAddAddressPopup}
                        numberOfAddressesSaved={account.customer ? account.customer.addresses.length : 0}
                        addressIndex={0}
                        addressToEdit={additionalAddressToEdit}
                        edit={edit}
                        theme={theme}
                        update={refreshAddresses}
                        keycloak={props.keycloak}
                    />
                )}
            </Dialog>
        </Portal>
    );
};

export default withTheme(CustomerData);

const DialogContent = ({
    theme,
    initialValues,
    validateSchema,
    settings,
    customerDataForm,
    formElements,
    paymentForm,
    windowWidth,
    windowHeight,
    step,
    setIsFormValid,
    handleSubmit,
    keycloak,
}) => {
    return (
        <Dialog.Content
            style={{
                backgroundColor: theme.colors.surface,
                padding: 0,
                paddingBottom: 0,
                paddingTop: 0,
                paddingLeft: 0,
                paddingRight: 0,
                paddingHorizontal: 0,
            }}
        >
            <Formik
                initialValues={initialValues}
                validationSchema={validateSchema}
                onSubmit={(values) => {
                    const selectedPaymentType = settings.store.availablePaymentTypes.find(
                        (type) => type.identifier === values.paymentMethod
                    );

                    if (!selectedPaymentType) {
                        console.error("Invalid payment type", error);
                        return;
                    }

                    const paymentTypesId = selectedPaymentType?.id;

                    const payload = {
                        ...values,
                        billingAddress: values.useDifferentBillingAddress
                            ? values.billingAddress
                            : values.deliveryAddress,
                    };
                    handleSubmit(payload, values.paymentMethod, paymentTypesId);
                }}
                innerRef={customerDataForm}
            >
                {(props) => {
                    return (
                        <Form>
                            <View style={customerDataStyles.fieldsWrapper}>
                                <View
                                    style={{
                                        display: step === 0 ? "flex" : "none",
                                        width: "100%",
                                        flexWrap: "wrap",
                                        flexDirection: "row",
                                    }}
                                >
                                    {formElements({ ...props, keycloak })}
                                </View>
                                <View
                                    style={{
                                        display: step === 1 ? "block" : "none",
                                        width: "100%",
                                    }}
                                >
                                    {paymentForm(props, windowWidth)}
                                </View>
                                <View
                                    style={{
                                        display: step === 2 ? "block" : "none",
                                        width: "100%",
                                    }}
                                >
                                    <Summary props={props} theme={theme} settings={settings} />
                                </View>
                            </View>
                        </Form>
                    );
                }}
            </Formik>
        </Dialog.Content>
    );
};

const DialogActions = ({
    windowWidth,
    theme,
    hideCustomerData,
    account,
    customerDataForm,
    submitButtonName,
    step,
    isFormValid,
    prevStep,
    nextStep,
    paymentMethod,
    datenschutzIsChecked,
    addresses,
    toggleMissingAddressPopup,
}) => {
    return (
        <Dialog.Actions style={{ paddingBottom: 0, paddingTop: 15 }}>
            <View
                style={[
                    windowWidth <= desktopBreakpoint
                        ? customerDataStyles.containerFullRow
                        : customerDataStyles.containerSplitRow,
                    {
                        paddingBottom: 0,
                    },
                ]}
            >
                {step === 0 && (
                    <Button
                        textColor={getContrastColor(theme.colors.primary)}
                        style={{
                            backgroundColor: theme.colors.notification,
                            marginRight: windowWidth <= desktopBreakpoint ? 0 : "2%",
                            marginBottom: windowWidth <= desktopBreakpoint ? 10 : 0,
                            width: windowWidth <= desktopBreakpoint ? "100%" : "48%",
                        }}
                        onPress={hideCustomerData}
                        uppercase={false}
                    >
                        Abbrechen
                    </Button>
                )}
                {step > 0 ? (
                    <Button
                        textColor={getContrastColor(theme.colors.primary)}
                        style={{
                            backgroundColor: theme.colors.primary,
                            marginRight: windowWidth <= desktopBreakpoint ? 0 : "2%",
                            marginBottom: windowWidth <= desktopBreakpoint ? 10 : 0,
                            width: windowWidth <= desktopBreakpoint ? "100%" : "48%",
                        }}
                        type="button"
                        onPress={prevStep}
                        uppercase={false}
                    >
                        Zurück
                    </Button>
                ) : null}
                {step === 0 || step === 1 ? (
                    <Button
                        textColor={getContrastColor(theme.colors.primary)}
                        style={{
                            backgroundColor: !datenschutzIsChecked ? theme.colors.disabled : theme.colors.primary,
                            marginRight: windowWidth <= desktopBreakpoint ? 0 : "2%",
                            marginBottom: windowWidth <= desktopBreakpoint ? 10 : 0,
                            width: windowWidth <= desktopBreakpoint ? "100%" : "48%",
                            opacity: !datenschutzIsChecked ? 0.95 : 1,
                        }}
                        onPress={() => {
                            if (account.customer && addresses.length < 1) {
                                toggleMissingAddressPopup();
                            } else {
                                nextStep();
                            }
                        }}
                        type="button"
                        disabled={!datenschutzIsChecked}
                        uppercase={false}
                    >
                        Weiter
                    </Button>
                ) : null}
                {step === 2 && (
                    <Button
                        textColor={getContrastColor(theme.colors.primary)}
                        style={{
                            backgroundColor: !datenschutzIsChecked ? theme.colors.disabled : theme.colors.primary,
                            marginRight: windowWidth <= desktopBreakpoint ? 0 : "2%",
                            marginBottom: windowWidth <= desktopBreakpoint ? 10 : 0,
                            width: windowWidth <= desktopBreakpoint ? "100%" : "48%",
                        }}
                        onPress={() => {
                            if (account.customer && addresses.length < 1) {
                                toggleMissingAddressPopup();
                            } else {
                                customerDataForm.current.submitForm();
                            }
                        }}
                        disabled={!datenschutzIsChecked}
                        uppercase={false}
                    >
                        {submitButtonName}
                    </Button>
                )}
            </View>
        </Dialog.Actions>
    );
};

const Summary = ({ props, theme, settings }) => {
    const cart = useSelector((state) => state.cart);

    const total = cart.orderItems
        .reduce((sum, item) => {
            return sum + item.Price.price1 * item.entry.amount;
        }, 0)
        .toFixed(2);

    const billingCountry = settings.store.countries.find(
        (country) => country.countryId === Number(props.values.billingCountry)
    );

    const deliveryCountry = settings.store.countries.find(
        (country) => country.countryId === Number(props.values.country)
    );

    const billingAddress = props.values.billingAddress
        ? JSON.parse(props.values.billingAddress)
        : {
              title: props.values.billingTitle,
              firstName: props.values.billingFirstName,
              lastName: props.values.billingLastName,
              street: props.values.billingStreet,
              housenumber: props.values.billingHousenumber,
              zip: props.values.billingZipCode,
              city: props.values.billingCity,
              country: billingCountry ? billingCountry.name : "",
          };
    const deliveryAddress = props.values.deliveryAddress
        ? JSON.parse(props.values.deliveryAddress)
        : {
              title: props.values.title,
              firstName: props.values.firstName,
              lastName: props.values.lastName,
              street: props.values.txtStreet,
              housenumber: props.values.housenumber,
              zip: props.values.txtZipCode,
              city: props.values.txtCity,
              country: deliveryCountry ? deliveryCountry.name : "",
          };

    const deliveryCountryFees = settings.store.countries.find(
        (country) => country.name === deliveryAddress.country
    ) || {
        deliveryFee: 0,
        deliveryFreeAt: 0,
        minOrderValue: 0,
        minOrderCharge: 0,
        name: "Unbekanntes Land",
    };

    const calculateMinOrderCharge = (total, deliveryCountryFees, settings) => {
        const minOrderValue = Number(deliveryCountryFees.minOrderValue);
        const minOrderCharge = Number(deliveryCountryFees.minOrderCharge);

        if (settings.store.dynamicMinOrderCharge) {
            return total < minOrderValue ? (minOrderValue - total).toFixed(2) : 0;
        } else {
            return total < minOrderValue && minOrderCharge ? minOrderCharge.toFixed(2) : 0;
        }
    };

    const minOrderCharge = calculateMinOrderCharge(total, deliveryCountryFees, settings);

    const deliveryFee =
        Number(deliveryCountryFees.deliveryFee) > 0 &&
        (Number(deliveryCountryFees.deliveryFreeAt) === 0 || total < Number(deliveryCountryFees.deliveryFreeAt))
            ? Number(deliveryCountryFees.deliveryFee).toFixed(2)
            : 0;

    const areAddressesEqual = (address1, address2) => {
        return (
            address1.title === address2.title &&
            address1.firstName === address2.firstName &&
            address1.lastName === address2.lastName &&
            address1.street === address2.street &&
            address1.housenumber === address2.housenumber &&
            address1.zip === address2.zip &&
            address1.city === address2.city
        );
    };

    return (
        props.values && (
            <>
                <View>
                    <DataTable>
                        <DataTableHeader theme={theme}>
                            <DataTableTitle theme={theme}>Lieferadresse</DataTableTitle>
                        </DataTableHeader>

                        <DataTableRow>
                            <DataTableCell>Vor- und Nachname</DataTableCell>
                            <DataTableCell numeric></DataTableCell>
                            <DataTableCell numeric></DataTableCell>
                            <DataTableCell numeric>
                                {deliveryAddress.title} {deliveryAddress.firstName} {deliveryAddress.lastName}
                            </DataTableCell>
                        </DataTableRow>

                        <DataTableRow>
                            <DataTableCell>Lieferungsadresse</DataTableCell>
                            <DataTableCell numeric></DataTableCell>
                            <DataTableCell numeric></DataTableCell>
                            <DataTableCell numeric>
                                {deliveryAddress.street} {deliveryAddress.housenumber}
                            </DataTableCell>
                        </DataTableRow>

                        <DataTableRow>
                            <DataTableCell>PLZ und Stadt</DataTableCell>
                            <DataTableCell numeric></DataTableCell>
                            <DataTableCell numeric></DataTableCell>
                            <DataTableCell numeric>
                                {deliveryAddress.zip} {deliveryAddress.city}
                            </DataTableCell>
                        </DataTableRow>

                        <DataTableRow>
                            <DataTableCell>Land</DataTableCell>
                            <DataTableCell numeric></DataTableCell>
                            <DataTableCell numeric></DataTableCell>
                            <DataTableCell numeric>{deliveryAddress.country}</DataTableCell>
                        </DataTableRow>
                    </DataTable>
                </View>
                {billingAddress && !areAddressesEqual(billingAddress, deliveryAddress) && (
                    <View>
                        <DataTable>
                            <DataTableHeader theme={theme}>
                                <DataTableTitle theme={theme}>Rechnungsadresse</DataTableTitle>
                            </DataTableHeader>

                            <DataTableRow>
                                <DataTableCell>Vor- und Nachname</DataTableCell>
                                <DataTableCell numeric></DataTableCell>
                                <DataTableCell numeric></DataTableCell>
                                <DataTableCell numeric>
                                    {billingAddress.title} {billingAddress.firstName} {billingAddress.lastName}
                                </DataTableCell>
                            </DataTableRow>

                            <DataTableRow>
                                <DataTableCell>Lieferungsadresse</DataTableCell>
                                <DataTableCell numeric></DataTableCell>
                                <DataTableCell numeric></DataTableCell>
                                <DataTableCell numeric>
                                    {billingAddress.street} {billingAddress.housenumber}
                                </DataTableCell>
                            </DataTableRow>

                            <DataTableRow>
                                <DataTableCell>PLZ und Stadt</DataTableCell>
                                <DataTableCell numeric></DataTableCell>
                                <DataTableCell numeric></DataTableCell>
                                <DataTableCell numeric>
                                    {billingAddress.zip} {billingAddress.city}
                                </DataTableCell>
                            </DataTableRow>

                            <DataTableRow>
                                <DataTableCell>Land</DataTableCell>
                                <DataTableCell numeric></DataTableCell>
                                <DataTableCell numeric></DataTableCell>
                                <DataTableCell numeric>{billingAddress.country}</DataTableCell>
                            </DataTableRow>
                        </DataTable>
                    </View>
                )}
                <View>
                    <DataTable>
                        <DataTableHeader theme={theme}>
                            <DataTableTitle theme={theme}>Zahlungsinfo</DataTableTitle>
                        </DataTableHeader>

                        <DataTableRow>
                            <DataTableCell style={{ textTransform: "capitalize" }}>
                                {settings.store.availablePaymentTypes.find(
                                    (type) => type.identifier === props.values.paymentMethod
                                )?.name || props.values.paymentMethod}
                            </DataTableCell>
                        </DataTableRow>
                    </DataTable>
                </View>
                {props.values.comment && (
                    <View>
                        <DataTable>
                            <DataTableHeader theme={theme}>
                                <DataTableTitle theme={theme}>Kommentar/Wünsche</DataTableTitle>
                            </DataTableHeader>

                            <DataTableRow>
                                <DataTableCell>{props.values.comment}</DataTableCell>
                            </DataTableRow>
                        </DataTable>
                    </View>
                )}
                <View>
                    <DataTable>
                        <DataTableHeader theme={theme}>
                            <DataTableTitle theme={theme}>Artikel</DataTableTitle>
                            <DataTableTitle theme={theme} numeric>
                                Größe
                            </DataTableTitle>
                            <DataTableTitle theme={theme} numeric>
                                Menge
                            </DataTableTitle>
                            <DataTableTitle theme={theme} numeric>
                                Preis
                            </DataTableTitle>
                        </DataTableHeader>

                        {cart.orderItems.map((item) => (
                            <DataTableRow key={item.orderItemId}>
                                <DataTableCell>{item.entry.name}</DataTableCell>
                                <DataTableCell numeric>{item.ArticleSize.Size.displayName}</DataTableCell>
                                <DataTableCell numeric>{item.entry.amount}</DataTableCell>
                                <DataTableCell numeric>
                                    {(item.Price.price1 * item.entry.amount).toFixed(2)}
                                    <Text>€</Text>
                                </DataTableCell>
                            </DataTableRow>
                        ))}

                        {deliveryFee > 0 && (
                            <DataTableRow>
                                <DataTableCell>Lieferkosten</DataTableCell>
                                <DataTableCell numeric></DataTableCell>
                                <DataTableCell numeric></DataTableCell>
                                <DataTableCell numeric>
                                    {deliveryFee.replace(".", ",")}
                                    <Text>€</Text>
                                </DataTableCell>
                            </DataTableRow>
                        )}

                        {/* Mindermengenzuschlag anzeigen, falls zutreffend */}
                        {minOrderCharge > 0 && (
                            <DataTableRow>
                                <DataTableCell>Mindermengenzuschlag</DataTableCell>
                                <DataTableCell numeric></DataTableCell>
                                <DataTableCell numeric></DataTableCell>
                                <DataTableCell numeric>
                                    {minOrderCharge.replace(".", ",")}
                                    <Text>€</Text>
                                </DataTableCell>
                            </DataTableRow>
                        )}

                        <DataTableRow>
                            <DataTableCell>Gesamt</DataTableCell>
                            <DataTableCell numeric></DataTableCell>
                            <DataTableCell numeric></DataTableCell>
                            <DataTableCell numeric>
                                {(Number(total) + Number(deliveryFee) + Number(minOrderCharge))
                                    .toFixed(2)
                                    .replace(".", ",")}
                                <Text>€</Text>
                            </DataTableCell>
                        </DataTableRow>
                    </DataTable>
                </View>
            </>
        )
    );
};

const DataTableHeader = ({ children, theme }) => {
    return (
        <DataTable.Header
            style={{
                backgroundColor: theme.colors.primary,
                borderBottomWidth: 0,
            }}
        >
            {children}
        </DataTable.Header>
    );
};

const DataTableRow = ({ children }) => {
    return <DataTable.Row style={{ cursor: "default", backgroundColor: "#ddd" }}>{children}</DataTable.Row>;
};

const DataTableCell = ({ children, numeric }) => {
    return (
        <DataTable.Cell style={{ cursor: "default" }} numeric={numeric ? numeric : false}>
            {children}
        </DataTable.Cell>
    );
};

const DataTableTitle = ({ children, numeric, theme }) => {
    return (
        <DataTable.Title
            textStyle={{
                color: getContrastColor(theme.colors.primary),
                textTransform: numeric ? "none" : "uppercase",
                fontWeight: numeric ? "normal" : "bold",
            }}
            numeric={numeric ? numeric : false}
        >
            {children}
        </DataTable.Title>
    );
};
