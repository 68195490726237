import { Picker } from "@react-native-picker/picker";
import React, { Component } from "react";
import { ScrollView, View, Image } from "react-native";
import { Button, Dialog, IconButton, Text } from "react-native-paper";
import { connect } from "react-redux";
import { styles } from "./AddToCardDialogStyles";
import { findPriceBySelectedArticleSize, getContrastColor } from "../../../shared/helpers";
import { styles as sharedStyles } from "../../../shared/styles";
import Config from "../../../../Config";
import AmountSelection from "./AmountSelection";
import ToastMessage from "../../../helpers/toastMessage/ToastMessage";

const formatter = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
});

class AddToCartDialog extends Component {
    constructor(props) {
        super(props);
        this.state = this.getInitialState(props);

        this.addArticleToCart = this.addArticleToCart.bind(this);
    }

    getInitialState(props) {
        const sortedArticleSizes = props.articleSizes.sort((a, b) => a.Size.sortorder - b.Size.sortorder);
        const initialArticleSizeId = props.selectedArticleSize
            ? props.selectedArticleSize.articleSizesId
            : sortedArticleSizes[0].articleSizesId;

        const { price, portionSize } = this.calculatePriceAndPortionSize(props.selectedArticle, initialArticleSizeId);

        return {
            selectedArticleSizeId: initialArticleSizeId,
            amount: 1,
            price: price,
            portionSize: portionSize,
            pricePer100g: (price / portionSize) * 100,
        };
    }

    addArticleToCart() {
        const selectedArticleSizeId = parseInt(this.state.selectedArticleSizeId, 10);
        const selectedArticleSize = this.props.articleSizes.find(
            (size) => size.articleSizesId === selectedArticleSizeId
        );
        this.props.addArticleToCart(selectedArticleSize, this.props.selectedArticle, this.state.amount);
        toast.show(
            <ToastMessage
                message={`Der Artikel ${this.props.selectedArticle.name} wurde erfolgreich in den Warenkorb gelegt!`}
                icon="cart"
                backgroundColor={this.props.settings.theme.colors.surface}
                style={{ zIndex: 0 }}
                textColor={getContrastColor(this.props.settings.theme.colors.surface)}
            />,
            {
                type: "success",
                placement: "top",
                duration: 5000,
                offset: 1000,
                offsetTop: 3000,
                animationType: "slide-in",
                style: { backgroundColor: "transparent", pointerEvents: "none !important" },
            }
        );
    }

    handleDateChange(newDate) {
        this.setState({
            selectedDate: newDate,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.selectedArticle.id !== this.props.selectedArticle.id) {
            const sortedArticleSizes = this.props.articleSizes.sort((a, b) => a.Size.sortorder - b.Size.sortorder);
            const newSelectedArticleSizeId = this.props.selectedArticleSize
                ? this.props.selectedArticleSize.articleSizesId
                : sortedArticleSizes[0].articleSizesId;

            const { price, portionSize } = this.calculatePriceAndPortionSize(
                this.props.selectedArticle,
                newSelectedArticleSizeId
            );
            this.setState({
                price: price,
                portionSize: portionSize,
                pricePer100g: (price / portionSize) * 100,
                selectedArticleSizeId: newSelectedArticleSizeId,
                amount: 1,
            });
        }
    }

    calculatePriceAndPortionSize = (selectedArticle, articleSizeId) => {
        return findPriceBySelectedArticleSize(
            selectedArticle.articleSizes,
            this.props.settings.store.orderTypes.orderTypesId,
            this.props.settings.store.priceGroups.priceGroupsId,
            articleSizeId
        );
    };

    handleArticleSizeChange = (itemValue) => {
        this.setState({ selectedArticleSizeId: itemValue }, () => {
            const { price, portionSize } = this.calculatePriceAndPortionSize(this.props.selectedArticle, itemValue);
            this.setState({
                price: price,
                portionSize: portionSize,
                pricePer100g: (price / portionSize) * 100,
            });
        });
    };

    render() {
        const config = new Config();
        const { theme } = this.props.settings;
        const { price, portionSize, pricePer100g } = this.state;
        const { selectedArticle } = this.props;
        const sortedArticleSizes = this.props.articleSizes.sort((a, b) => a.Size.sortorder - b.Size.sortorder);

        return (
            <Dialog
                visible={this.props.addToCartVisible}
                onDismiss={this.props.toggleAddToCartDialog}
                style={[
                    styles.addressDialogContainer,
                    {
                        backgroundColor: theme.colors.surface,
                        left: "calc(50% - 330px)",
                        width: "660px",
                    },
                ]}
                dismissable={false}
            >
                <IconButton
                    icon="close"
                    size={24}
                    onPress={this.props.toggleAddToCartDialog}
                    style={[sharedStyles.dialogCloseButton, { backgroundColor: theme.colors.primary }]}
                    iconColor={getContrastColor(theme.colors.primary)}
                    rippleColor="transparent"
                />
                <Dialog.Title style={[sharedStyles.dialogHeadline]}>
                    <Text>Zum Warenkorb hinzufügen</Text>
                </Dialog.Title>
                <Dialog.ScrollArea
                    style={[
                        sharedStyles.dialogScrollArea,
                        {
                            borderColor: theme.colors.surface,
                        },
                    ]}
                >
                    <ScrollView>
                        <Dialog.Content>
                            <View style={{ alignItems: "center", justifyContent: "center", marginBottom: 20 }}>
                                <Text style={styles.addToCartArticleName}>{selectedArticle.name}</Text>
                            </View>
                            <View style={[styles.twoColContainer, { marginBottom: 20 }]}>
                                <View style={styles.col1Container}>
                                    <View nativeID="articleImage">
                                        <Image
                                            style={styles.addToCartImage}
                                            source={{
                                                uri: selectedArticle.onlineShopTileImage
                                                    ? config.imageStorageBlobURL + selectedArticle.onlineShopTileImage
                                                    : selectedArticle.guidFileName
                                                    ? config.imageStorageBlobURL + selectedArticle.guidFileName
                                                    : "https://ggbiosmedia.blob.core.windows.net/ggbiosmedia/c57c74ca-9b1a-4e86-9f13-d1131d8dd670.png",
                                            }}
                                            resizeMode="cover"
                                        />
                                    </View>
                                </View>
                                <View style={styles.col2Container}>
                                    <View
                                        nativeID="addToCartPriceBox"
                                        style={[
                                            styles.addToCartPriceBoxWrapper,
                                            {
                                                backgroundColor: theme.colors.surface,
                                            },
                                        ]}
                                    >
                                        <View style={styles.addToCartPriceBoxInnerWrapper}>
                                            {price !== 0 && (
                                                <View style={styles.addToCartPriceBox}>
                                                    <Text
                                                        style={[
                                                            styles.addToCartPriceBoxHeadline,
                                                            { color: getContrastColor(theme.colors.surface) },
                                                        ]}
                                                    >
                                                        Einzelpreis
                                                    </Text>
                                                    <Text
                                                        style={[
                                                            styles.addToCartPriceBoxPriceText,
                                                            {
                                                                color: getContrastColor(theme.colors.surface),
                                                            },
                                                        ]}
                                                    >
                                                        {formatter.format(price)}
                                                    </Text>
                                                    <Text
                                                        style={[
                                                            styles.addToCartPriceBoxBasePriceText,
                                                            {
                                                                color: getContrastColor(theme.colors.surface),
                                                            },
                                                        ]}
                                                    >
                                                        ({formatter.format(pricePer100g)} / 100 g)
                                                    </Text>
                                                </View>
                                            )}
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.twoColContainer}>
                                <View style={[styles.col1Container, { justifyContent: "flex-start" }]}>
                                    <View nativeID="articleSizeSelection">
                                        <Text style={[styles.addToCartDialogSelectLabel]}>Größe</Text>
                                        {sortedArticleSizes.length > 1 ? (
                                            <Picker
                                                selectedValue={this.state.selectedArticleSizeId}
                                                onValueChange={(itemValue, itemIndex) =>
                                                    this.handleArticleSizeChange(itemValue)
                                                }
                                                style={[
                                                    styles.addToCartDialogSelect,
                                                    {
                                                        backgroundColor: theme.colors.surface,
                                                        borderColor: theme.colors.primary,
                                                        color: getContrastColor(theme.colors.surface),
                                                    },
                                                ]}
                                            >
                                                {sortedArticleSizes.map((articleSize, index) => (
                                                    <Picker.Item
                                                        key={index}
                                                        label={articleSize.Size.name}
                                                        value={articleSize.articleSizesId}
                                                    />
                                                ))}
                                            </Picker>
                                        ) : sortedArticleSizes[0] && sortedArticleSizes[0].Size.displayName ? (
                                            <Text style={styles.addToCartSizeStandardText}>
                                                {`${sortedArticleSizes[0].Size.displayName} - ${sortedArticleSizes[0].portionSize} g`}
                                            </Text>
                                        ) : (
                                            <></>
                                        )}
                                    </View>
                                </View>
                                <View style={styles.col2Container}>
                                    <Text style={[styles.addToCartDialogSelectLabel]}>Menge</Text>
                                    <AmountSelection
                                        initialAmount={this.state.amount}
                                        maxAmount={this.props.settings.store.maxPortionNumber}
                                        theme={theme}
                                        onAmountChange={(newAmount) => this.setState({ amount: newAmount })}
                                    />
                                </View>
                            </View>
                        </Dialog.Content>
                    </ScrollView>
                </Dialog.ScrollArea>
                <Dialog.Actions>
                    <View
                        style={[
                            sharedStyles.dialogButtonContainerVertical,
                            {
                                paddingBottom: 0,
                            },
                        ]}
                    >
                        <Button
                            textColor={getContrastColor(theme.colors.primary)}
                            style={[
                                styles.addToCartDialogButton,
                                {
                                    backgroundColor: theme.colors.error,
                                    marginRight: "2%",
                                    marginBottom: 0,
                                    width: "48%",
                                },
                            ]}
                            onPress={this.props.toggleAddToCartDialog}
                            rippleColor="transparent"
                        >
                            Abbrechen
                        </Button>

                        <Button
                            textColor={getContrastColor(theme.colors.primary)}
                            style={[
                                styles.addToCartDialogButton,
                                {
                                    backgroundColor: theme.colors.primary,
                                    marginRight: "2%",
                                    marginBottom: 0,
                                    width: "48%",
                                },
                            ]}
                            onPress={this.addArticleToCart}
                            rippleColor="transparent"
                        >
                            In den Warenkorb legen
                        </Button>
                    </View>
                </Dialog.Actions>
            </Dialog>
        );
    }
}
function mapStateToProps(state) {
    const { settings } = state;
    return {
        settings: settings,
    };
}

export default connect(mapStateToProps)(AddToCartDialog);
