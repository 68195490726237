import React from "react";
import { View, StyleSheet } from "react-native";
import { Text } from "react-native-paper";
import { Ionicons } from "@expo/vector-icons";

const ToastMessage = ({ message = "", icon = "", backgroundColor = "#ffffff", textColor = "#000000" }) => {
    return (
        <View style={{ ...StyleSheet.flatten(styles.container), backgroundColor }}>
            {icon && <Ionicons name={icon} size={24} color={textColor} />}
            <Text style={{ ...StyleSheet.flatten(styles.text), color: textColor }}>{message}</Text>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        alignItems: "center",
        padding: 10,
        borderRadius: 5,
        boxShadow: "0 0 10px 0 rgba(0,0,0,0.2)",
        flexShrink: 1,
        pointerEvents: "none !important",
    },
    text: {
        fontFamily: "regular",
        marginLeft: 10,
        flexShrink: 1,
    },
});

export default ToastMessage;
